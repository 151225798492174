import React from 'react';
import GlobalStyles from 'components/GlobalStyles';
import flattenMessages from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import App, { AppContext, AppInitialProps } from 'next/app';
import { defaultTheme } from 'constants/theme/defaultTheme';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { AuthProvider } from 'contexts/AuthProvider/AuthProvider';
import * as logging from 'services/logging';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'services/apollo/client';
import { initFirebase, fetchAndActivateRemoteConfig } from 'services/featureFlags';
import { ModalProvider } from 'contexts/ModalProvider/ModalProvider';
import { OnboardingProvider } from 'contexts/OnboardingProvider/OnboardingProvider';
import { initAnalytics, logScreenTrackingEvent } from 'services/analytics/helpers';
import { withRouter, NextRouter } from 'next/router';
import { AlgoliaIndexes } from 'models/products';
import { InstantSearch } from 'react-instantsearch-core';
import { searchClient } from 'services/algolia';
import { CompanyProvider } from 'contexts/CompanyProvider/CompanyProvider';
import { OverlayProvider } from 'contexts/OverlayProvider/OverlayProvider';
import { NextSeo } from 'next-seo';

logging.init();

interface AppProps extends AppInitialProps {
  hasError: boolean;
  errorEventId?: string;
}

const locales = {
  en: flattenMessages(enMessages),
};

type AppState = {
  initComplete: boolean;
  routeHistory: string[];
};

class MyApp extends App<
  AppProps & {
    router: NextRouter;
  },
  unknown,
  AppState
> {
  state = { initComplete: false, routeHistory: [] };

  handleRouteChange = (url: string) => {
    const [path, query] = url.split('?');
    logScreenTrackingEvent({
      // from is the second last route in the routeHistory
      from: this.state.routeHistory[this.state.routeHistory.length - 2] || '',
      to: path,
      query,
    });
  };

  static async getInitialProps(props: AppContext): Promise<AppProps> {
    const { Component, ctx } = props;
    try {
      const pageProps =
        Component.getInitialProps !== undefined ? await Component.getInitialProps(ctx) : {};
      return { pageProps, hasError: false, errorEventId: undefined };
    } catch (error) {
      logging.logError({ error, errorName: 'MyAppError' });
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      return {
        hasError: true,
        pageProps: {},
      };
    }
  }

  componentDidMount() {
    const init = async () => {
      await initAnalytics();
      await initFirebase();
      await fetchAndActivateRemoteConfig();
      this.setState({ initComplete: true });
      const { pathname } = this.props.router;
      this.setState(prevState => ({ routeHistory: [...prevState.routeHistory, pathname] }));
      logScreenTrackingEvent({ to: window.location.pathname, from: '' });
      this.props.router.events.on('routeChangeComplete', this.handleRouteChange);
    };

    init();
  }

  componentWillUnmount() {
    this.props.router.events.off('routeChangeComplete', this.handleRouteChange);
  }

  componentDidCatch(error: Error) {
    logging.logError({ error, errorName: 'MyAppError' });
  }

  componentDidUpdate() {
    const { routeHistory } = this.state;
    const { asPath } = this.props.router;

    // if current route does not equal the latest item
    // in the routeHistory, add it to routeHistory state
    if (routeHistory[routeHistory.length - 1] && routeHistory[routeHistory.length - 1] !== asPath) {
      this.setState(prevState => ({ routeHistory: [...prevState.routeHistory, asPath] }));
    }
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <AuthProvider>
        {pageProps && pageProps.hit && (
          <NextSeo
            title={`${pageProps.hit.productName} | vTail Healthcare`}
            description={pageProps.hit.productDescription}
            openGraph={{
              title: `${pageProps.hit.productName} | vTail Healthcare`,
              description: pageProps.hit.productDescription,
              images: [
                {
                  url: pageProps.hit.skuImage,
                  width: 800,
                  height: 600,
                  alt: pageProps.hit.productName,
                  type: 'image/jpeg',
                },
              ],
              site_name: 'vTail',
            }}
            twitter={{
              handle: '@vtailapp',
              site: '@vtailapp',
              cardType: 'summary_large_image',
            }}
          />
        )}
        <GlobalStyles />
        <ApolloProvider client={apolloClient}>
          <IntlProvider locale="en" messages={locales.en}>
            <ThemeProvider theme={defaultTheme}>
              <CompanyProvider>
                <OverlayProvider>
                  <ModalProvider>
                    <InstantSearch searchClient={searchClient} indexName={AlgoliaIndexes.Products}>
                      <OnboardingProvider>
                        {this.state.initComplete ? <Component {...pageProps} /> : <></>}
                      </OnboardingProvider>
                    </InstantSearch>
                  </ModalProvider>
                </OverlayProvider>
              </CompanyProvider>
            </ThemeProvider>
          </IntlProvider>
        </ApolloProvider>
      </AuthProvider>
    );
  }
}

export default withRouter(MyApp);
