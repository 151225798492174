import React, { createContext, useContext, useEffect, useState } from 'react';
import Amplify from '@aws-amplify/core';
import { awsConfig } from '../../awsConfig';
import {
  getCurrentUser,
  getCurrentUserGroup,
  getUserId,
  isUserAuthenticated,
  USER_GROUPS,
} from 'services/auth';
import { logError } from 'services/logging';
import { useRouter } from 'next/router';
import { PATHS } from 'constants/paths';
import * as Sentry_ from '@sentry/browser';
import { shutdownIntercom } from 'next-intercom';

export type LoggedInUser = {
  auth: Record<string, unknown>;
  isVTailAdmin: boolean;
  isBUAdmin: boolean;
  userGroup: string | undefined;
};

export type AuthContextType = {
  user: LoggedInUser | undefined;
  setUser: (u: Record<string, unknown>) => void;
};

const defaultValue: AuthContextType = {
  setUser: () => {},
  user: undefined,
};

const unauthenticatedRoutes = [
  PATHS.VERIFY,
  PATHS.ONBOARDING_INTRO,
  PATHS.ONBOARDING_WELCOME,
  PATHS.PRODUCTS_DETAIL,
  PATHS.PRODUCTS_SEARCH,
];

export const AuthContext = createContext<AuthContextType>(defaultValue);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<LoggedInUser>();
  const { push, pathname } = useRouter();

  const setUserAction = async (user: Record<string, unknown>) => {
    const userGroup = await getCurrentUserGroup();
    const userId = await getUserId();
    const loggedIn: LoggedInUser = {
      auth: user,
      userGroup,
      isVTailAdmin: userGroup === USER_GROUPS.VTAIL_ADMIN,
      isBUAdmin: userGroup === USER_GROUPS.BUSINESS_UNIT_ADMIN,
    };
    setUser(loggedIn);
    if (userId) {
      Sentry_.configureScope(scope => {
        scope.setUser({
          id: userId,
          type: userGroup,
        });
      });
    } else {
      logError({
        error: new Error(`user id undefined for user - ${user}`),
        errorName: 'AuthProviderError',
      });
    }

    if (loggedIn.isVTailAdmin || loggedIn.isBUAdmin) {
      shutdownIntercom();
    }
  };

  useEffect(() => {
    const setupUserIfAuthenticated = async () => {
      Amplify.configure(awsConfig);
      const userOnAuthenticatedRoute = !unauthenticatedRoutes.includes(pathname as PATHS);
      const userIsAuthenticated = await isUserAuthenticated();

      if (userIsAuthenticated) {
        const user = await getCurrentUser();
        if (user) {
          setUserAction(user);
        }
      } else if (!userIsAuthenticated && userOnAuthenticatedRoute) {
        push(`${PATHS.LOGIN}`);
      }
    };

    setupUserIfAuthenticated().catch(error => {
      setUser(undefined);
      logError({
        error,
        errorName: 'AuthProviderError',
        message: 'AuthProvider, user is undefined',
      });
    });
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser: setUserAction }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('must be in the AuthProvider to access this');
  }

  return context;
};
