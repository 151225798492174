export enum LocalStorageItems {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  zipCode = 'zipCode',
  businessName = 'businessName',
  country = 'country',
  formattedAddress = 'formattedAddress',
  state = 'state',
  areaOfSpecialisationId = 'areaOfSpecialisationId',
  areasOfPracticeIds = 'areasOfPracticeIds',
  roleIds = 'roleIds',
  sitesOfCareIds = 'sitesOfCareIds',
  id = 'id',
  locationSuggestion = 'locationSuggestion',
  isUserReferred = 'isUserReferred',
  referrerUserId = 'referrerUserId',
  onboardingCompleted = 'onboardingCompleted',
}

class LocalStorage {
  // Can be multiple types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(key: LocalStorageItems, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: LocalStorageItems) {
    const item = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  }

  removeItem(key: LocalStorageItems) {
    window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
  }
}

export const localStorageClass = new LocalStorage();
