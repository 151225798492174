import { TypeOfObjectKey } from 'utils/types/types';

export enum CustomEventName {
  'EmployeeCreated' = 'EmployeeCreated',
  'EmployeeUpdated' = 'EmployeeUpdated',
  'NewsFeedItemDraftSaved' = 'NewsFeedItemDraftSaved',
  'NewsfeedItemDraftPublished' = 'NewsfeedItemDraftPublished',
  'Admin_CompanyCreated' = 'Admin_CompanyCreated',
  'Admin_CompanyEdited' = 'Admin_CompanyEdited',
  'Admin_BusinessUnitCreated' = 'Admin_BusinessUnitCreated',
  'Admin_BusinessUnitAdminCreated' = 'Admin_BusinessUnitAdminCreated',
  'Web_Onboarding_Attributed' = 'Web_Onboarding_Attributed',
  'Web_Clinician_OnboardingCompleted' = 'Web_Clinician_OnboardingCompleted',
  'Web_Onboarding_Started' = 'Web_Onboarding_Started',
  'App_Link_SMS_Sent' = 'App_Link_SMS_Sent',
  'Product_Search_Click' = 'Product_Search_Click',
  'Product_Footer_Sign_Up' = 'Product_Footer_Sign_Up',
  'ProductTapped' = 'ProductTapped',
  'Product_Contact_Rep' = 'Product_Contact_Rep',
  'Product_Open_Document' = 'Product_Open_Document',
  'Product_Open_Related' = 'Product_Open_Related',
  'Product_Filter_Click' = 'Product_Filter_Click',
  'Product_Filter_AOS_Click' = 'Product_Filter_AOS_Click',
  'ReportProductProblem' = 'ReportProductProblem',
}

export type TrackingEvent = {
  name: CustomEventName;
  data?: Record<string, string | number>;
};

export type UserAttributeEvent = {
  userAttribute: UserAttributeType;
  value: string | string[];
};

// See MP reserved attributes:
// https://docs.mparticle.com/developers/sdk/ios/users/#reserved-attributes

export enum UserAttributeType {
  FirstName = '$FirstName',
  LastName = '$LastName',
  Email = 'Email',
  Group = 'Group',
  JobTitle = 'JobTitle',
  AreasOfPractice = 'AreasOfPractice',
  SitesOfCare = 'SitesOfCare',
  AreasOfSpecialisation = 'AreasOfSpecialisation',
  Roles = 'Roles',
  ZipCode = 'ZipCode',
  State = 'State',
  Attribution = 'Attribution',
  NotificationPermissions = 'NotificationPermissions',
  ShareUrl = 'ShareUrl',
  UserReferralAttribution = 'UserReferralAttribution',
  // Name used only for intercom web sdk, and key is case sensitive
  Name = 'name',
}

export type MparticleUser = {
  setUserAttribute: (userAttributeType: UserAttributeType, value: string | string[]) => void;
  setUserAttributeList: (userAttributeType: UserAttributeType, value: string | string[]) => void;
};

export type ButtonTapTrackingEvent = {
  text: string;
};

export type ScreenTrackingEvent = {
  from: string;
  to: string;
  query?: string;
};

export type AttributionEvent = {
  data: string;
  data_parsed: {
    $og_title: string;
    $marketing_title: string;
    '~creation_source': number;
    $og_description: string;
    '+click_timestamp': number;
    $og_image_url: string;
    '~feature': string;
    '+match_guaranteed': boolean;
    $web_only: string;
    $desktop_url: string;
    '~marketing': boolean;
    '+clicked_branch_link': boolean;
    '~id': number;
    $one_time_use: boolean;
    '~campaign': string;
    '+is_first_session': boolean;
    '~referring_link': string;
    '~channel': string;
    $android_url: string;
    $ios_url: string;
    referrer_user_id?: string;
  };
  has_app: boolean;
  identity: object | null;
  developer_identity: object | null;
  referring_identity: object | null;
  referring_link: string;
};

type DataType = TypeOfObjectKey<TrackingEvent, 'data'>;

export const cleanDataAnalytics = <T extends object>(obj: T): DataType => {
  return Object.entries(obj).reduce(
    (acc: DataType, [key, value]: [string, string | number]): DataType => {
      if (typeof value === 'undefined') {
        return acc;
      }
      return { ...acc, [key]: value };
    },
    {},
  );
};
