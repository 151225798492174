export const TEST_IDS = {
  navbar: {
    logo: 'navbar_logo',
    links: {
      companyEmployees: 'navbar_links_companyEmployees',
      articles: 'navbar_links_articles',
      companies: 'navbar_links_companies',
      employees: 'navbar_links_employees',
      clinicians: 'navbar_links_clinicians',
    },
  },
  signInForm: {
    email: 'signInForm_email',
    password: 'signInForm_password',
    button: 'signInForm_button',
  },
  modal: {
    closeButton: 'modal_closeButton',
  },
  onboarding: {
    intro: {
      firstName: 'first name input',
      lastName: 'last name input',
      email: 'email input',
      password: 'password input',
      passwordConfirmation: 'password confirmation input',
      submit: 'submit button',
    },
    aos: {
      submit: 'submit aos',
    },
    productGroups: {
      title: 'product groups title',
      submit: 'submit product groups',
    },
    clinicalAreaDetails: {
      zipCode: 'zipcode input',
      location: 'location input',
      role: 'select_Role select',
      soc: 'select_Site of care select',
      businessName: 'business name input',
      submit: 'submit btn',
    },
  },
  products: {
    searchProductsInput: {
      close: 'search_products_input_close',
      input: 'search_products_input_input',
    },
    productsHeader: {
      search: 'products_header_search',
      logo: 'products_header_logo',
      back: 'products_header_back',
      filter: 'products_header_filter',
    },
    emptyState: {
      heading: 'products_empty_state_heading',
    },
    searchResult: {
      name: 'product_search_result_name',
      moreVariantsText: 'product_search_result_more-variants',
    },
    searchFiltersModal: 'product_search_filters_modal',
    searchFiltersButton: 'product_search_filters_button',
    searchFiltersModalCloseButton: 'product_search_filters_modal_close_btn',
    searchFiltersModalShowButton: 'product_search_filters_modal_show_btn',
    searchFiltersModalClearButton: 'product_search_filters_modal_clear_btn',
  },
  businessUnits: {
    deleteButton: 'buadmin_delete_button',
    deleteUnitButton: 'bu_delete_button',
  },
  profileDetails: {
    archiveButton: 'archive_employee_button',
  },
  addEmployeeModal: {
    firstName: 'addEmployee_firstName',
    lastName: 'addEmployee_lastName',
    email: 'addEmployee_email',
    jobTitle: 'addEmployee_jobTitle',
    areasOfPractice: 'select_areasOfPractice',
    sitesOfCare: 'select_sitesOfCare',
    areasOfSpecialisation: 'select_areasOfSpecialisation',
    states: 'select_locations',
    zipcodes: 'addEmployee_zipcodes',
    createButton: 'addEmployee_create_button',
    addAosButton: 'addEmployee_aos_button',
    confirmAosButton: 'confirmAosButton',
  },
  aosRow: {
    deleteAosButton: 'delete_aos_button',
  },
  addSOCForm: {
    primaryCheckboxes: 'soc_checkbox_container',
    submit: 'soc_submit',
    category: 'soc-add-category',
    additionalSitesRadio: 'soc_add_additional_radio',
  },
  articlesTable: 'articlesTable',
  articlesTableBody: 'articlesTableBody',
  articlesTableRow: 'articlesTableRow',
  addArticleButton: 'addArticleButton',
  addArticleModal: 'addArticleModal',
  articleDetailsImageUpload: 'articleDetailsImageUpload',
  employeeTable: 'employeeTable',
  employeeTableBody: 'employeeTableBody',
  employeeDetails: 'employeeDetails',
  addEmployeesButton: 'addEmployeesButton',
  addEmployeesModal: 'addEmployeesModal',
  admin: {
    actions: 'admin.actions',
    companyTable: 'admin.companyTable',
    companyDetails: 'admin.companyDetails',
    createCompanyButton: 'admin.createCompanyButton',
    businessUnitAdmins: 'admin.BusinessUnitAdmins',
    addCompanyModal: 'admin.addCompanyModal',
    companyElement: 'admin.companyElement',
    companyElementItem: 'admin.companyElementItem',
    clinicians: {
      searchInput: {
        input: 'search_clinicians_input_input',
        searchButton: 'search_clinicians_input_button',
        close: 'search_clinicians_input_close',
      },
      table: 'admin.clinicianTable',
      elements: 'admin.clinicianElement',
      elementItem: 'admin.clinicianElementItem',
      archiveButton: 'archive_clinician_button',
      archiveInlineLoader: 'archive_clinician_inline_loader',
    },
  },
  atoms: {
    arrow: {
      up: 'arrow_up',
      down: 'arrow_down',
    },
    accordion: {
      heading: 'accordion_heading',
      content: 'accordion_content',
    },
    checkboxes: {
      title: 'checkboxes',
    },
  },
  adminAddCompany: {
    description: 'adminAddCompany.description',
    descriptionError: 'adminAddCompany.descriptionError',
    name: 'adminAddCompany.name',
    weighting: 'adminAddCompany.weighting',
    weightingError: 'adminAddCompany.weightingError',
    nameError: 'adminAddCompany.nameError',
    imageUpload: 'adminAddCompany.imageUpload',
    wideImageUpload: 'adminAddCompany.wideImageUpload',
    heroImageUpload: 'adminAddCompany.heroImageUpload',
    imageUploadError: 'adminAddCompany.imageUploadError',
    submitButton: 'adminAddCompany.submitButton',
    website: 'adminAddCompany.website',
    websiteError: 'adminAddCompany.websiteError',
    contactUrl: 'adminAddCompany.contactUrl',
    contactUrlError: 'adminAddCompany.contactUrlError',
    addAos: 'adminAddCompany.addAos',
  },
  adminAddBU: {
    name: 'adminAddBU.name',
    submitButton: 'adminAddBU.submitButton',
    addAdminAddBUButton: 'adminAddBU.addAdminAddBUButton',
    addAdminAddBUModal: 'adminAddBU.addAdminAddBUModal',
  },
  adminEditCompany: {
    adminEditCompanyButton: 'adminEditCompany.adminEditCompanyButton',
    adminEditCompanyDeleteButton: 'adminEditCompany.adminDeleteCompanyButton',
  },
  adminAddBUAdmin: {
    firstName: 'adminAddBUAdmin.firstName',
    lastName: 'adminAddBUAdmin.lastName',
    email: 'adminAddBUAdmin.email',
    submitButton: 'adminAddBUAdmin.submitButton',
    addAdminAddBUAdminButton: 'adminAddBUAdmin.addAdminAddBUAdminButton',
    addAdminAddBUAdminModal: 'adminAddBUAdmin.addAdminAddBUAdminModal',
  },
  articles: {
    heading: 'articles.heading',
    website: 'articles.website',
    detailsBody: 'article.detailsBody',
    nextButton: 'next_button',
    publishButton: 'publish_button',
    backButton: 'back_button',
    areasOfPractice: 'select_areas_of_practice',
    readyToPublishButton: 'checkbox-draft',
    errorSection: 'articles.errorSection',
  },
  forgottenPassword: {
    form: 'forgottenPassword.form',
    emailInput: 'forgottenPassword.emailInput',
    emailInputError: 'forgottenPassword.emailInputError',
    submitButton: 'forgottenPassword.submitButton',
  },
  createNewPassword: {
    form: 'createNewPassword.form',
    codeInput: 'createNewPassword.codeInput',
    codeInputError: 'createNewPassword.codeInputError',
    passwordInput: 'createNewPassword.passwordInput',
    passwordInputError: 'createNewPassword.passwordInputError',
    passwordConfirmationInput: 'createNewPassword.passwordConfirmationInput',
    passwordConfirmationInputError: 'createNewPassword.passwordConfirmationInputError',
    submitButton: 'createNewPassword.submitButton',
    resendCodeButton: 'createNewPassword.resendCodeButton',
  },
  adminEmployeeTable: 'adminEmployeeTable',
  employeeDetailsPanel: {
    siteOfCare: 'site-of-care-details-item',
  },
  popup: {
    title: 'popup_title',
    button: 'products_popup_button',
    closeButton: 'products_popup_close_button',
  },
  overlayText: 'overlay-text',
  overlayButton: 'overlay-button',
  reportProblem: {
    submit: 'report_problem_submit',
    textArea: 'report_problem_text_area',
    okay: 'report_problem_okay',
  },
};
