import React, { useContext, useState, createContext } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { getCompanies } from 'queries/company/getCompanies';
import { formatCompanies } from 'utils/formatForDropdown/formatForDropdown';
import { logError } from 'services/logging';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { GetCompaniesOutput } from '../../../../types/graphql';

type QueryTypes = {
  getCompanies: GetCompaniesOutput[];
};

type CompanyContextProps = {
  companies?: GetCompaniesOutput[];
  companyDetailId: string;
  setCompanies: (comps: GetCompaniesOutput[]) => void;
  setCompanyDetailId: (companyId: string) => void;
  loading: boolean;
};

export const CompanyContext = createContext<CompanyContextProps | null>(null);

export const CompanyProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [companies, setCompanies] = useState<GetCompaniesOutput[]>();
  const [companyDetailId, setCompanyDetailId] = useState('');

  const { loading } = useQuery<QueryTypes>(getCompanies, {
    onCompleted: data => {
      const formatted = formatCompanies([...data.getCompanies]);
      setCompanies(formatted);
    },
    onError: (error: ApolloError) => {
      logError({ error, errorName: 'CompanyProviderError' });
    },
    fetchPolicy: 'cache-and-network',
    skip: !user || !user.isVTailAdmin,
  });

  return (
    <CompanyContext.Provider
      value={{
        companies,
        companyDetailId,
        setCompanies,
        setCompanyDetailId,
        loading,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = (): CompanyContextProps => {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error('must be in the CompanyProvider to access this');
  }

  return context;
};
