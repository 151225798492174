import { logError } from 'services/logging';
import {
  AttributionEvent,
  ButtonTapTrackingEvent,
  ScreenTrackingEvent,
  TrackingEvent,
  UserAttributeEvent,
  UserAttributeType,
} from './events';
import mParticle, { MPConfiguration } from '@mparticle/web-sdk';
import { getUserAttributes, getUserId } from 'services/auth';
import { unix } from 'dayjs';
import { localStorageClass, LocalStorageItems } from 'utils/localStorage/localStorage';
import { updateIntercom, loadIntercom, trackEvent } from 'next-intercom';
import * as Sentry from '@sentry/browser';

const isDevOrUITestEnv = process.env.ENVIRONMENT === 'dev' || process.env.ENVIRONMENT === 'uitest';

const showDebug = false;

export const initAnalytics = async () => {
  const mParticleConfig: MPConfiguration = {
    isDevelopmentMode: process.env.MPARTICLE_ENVIRONMENT === 'development',
    identityCallback: identityResult => {
      const mParticleID = identityResult.getUser().getMPID();
      Sentry.setContext('mParticle', {
        mParticleID,
      });
    },
  };
  mParticle.init(process.env.MPARTICLE_KEY ?? '', mParticleConfig);

  loadIntercom({
    appId: process.env.INTERCOM_APP_ID,
    custom_launcher_selector: '.intercom-custom-launcher',
  });
};

export const logTrackingEvent = async (event: TrackingEvent): Promise<void> => {
  if (isDevOrUITestEnv) {
    if (showDebug) {
      // eslint-disable-next-line no-console
      console.log(event);
    }
    return;
  }

  try {
    mParticle.logEvent(event.name, mParticle.EventType.Other, {
      platform: 'web',
      ...event.data,
    });

    trackEvent(event.name, {
      platform: 'web',
      ...event.data,
    });
  } catch (error) {
    logError({ error, errorName: 'LogTrackingError' });
  }
};

export const trackUserAttribute = async (event: UserAttributeEvent) => {
  if (isDevOrUITestEnv) {
    return;
  }

  try {
    // do not send Name to mparticle, as we give it first name & last name
    if (event.userAttribute !== UserAttributeType.Name) {
      const currentUser = mParticle.Identity.getCurrentUser();
      if (Array.isArray(event.value)) {
        currentUser.setUserAttributeList(event.userAttribute, event.value);
      } else {
        currentUser.setUserAttribute(event.userAttribute, event.value);
      }
    }

    // do not update intercom with first and last name, as it only takes name
    if (
      event.userAttribute !== UserAttributeType.FirstName &&
      event.userAttribute !== UserAttributeType.LastName
    ) {
      updateIntercom('update', { [event.userAttribute.toLowerCase()]: event.value });
    }
  } catch (error) {
    logError({ error, errorName: 'TrackUserAttributeError' });
  }
};

export const identifyCallback = (error: Error) => {
  if (error) {
    logError({ error, errorName: 'IdentifyCallbackError' });
  }
};

export const logButtonTapEvent = (event: ButtonTapTrackingEvent, location: string) => {
  if (isDevOrUITestEnv) {
    return;
  }

  try {
    mParticle.logEvent('ButtonTap', mParticle.EventType.Other, {
      text: event.text,
      screen: location,
      platform: 'web',
    });

    trackEvent('ButtonTap', {
      text: event.text,
      screen: location,
      platform: 'web',
    });
  } catch (error) {
    logError({ error, errorName: 'LogButtonTapEventError' });
  }
};

export const logScreenTrackingEvent = (event: ScreenTrackingEvent) => {
  if (isDevOrUITestEnv) {
    return;
  }

  try {
    mParticle.logPageView(event.to, {
      from: event.from,
      ...(event.query && { query: event.query }),
      platform: 'web',
    });
    trackEvent('Viewed', {
      to: event.to,
      from: event.from,
      ...(event.query && { query: event.query }),
      platform: 'web',
    });
  } catch (error) {
    logError({ error, errorName: 'LogScreenTrackingEventError' });
  }
};

// Unlike app, loginToAnalytics tracks both customierId and email
// for both mParticle an Intercom
export const loginToAnalytics = async () => {
  if (isDevOrUITestEnv) {
    return;
  }

  const { email } = await getUserAttributes();
  const customerid = await getUserId();
  const userIdentities = { email, customerid };
  mParticle.Identity.login({ userIdentities }, loginCallback);

  updateIntercom('update', {
    user_id: customerid,
    email,
  });
};

export const logAttributionData = async (data: AttributionEvent) => {
  if (isDevOrUITestEnv || !data.referring_link) {
    return;
  }

  try {
    const linkTitle = data.data_parsed.$marketing_title;
    const referringLink = data.data_parsed['~referring_link'];
    const clickUnixDateTime = data.data_parsed['+click_timestamp'];
    const clickDateTime = unix(clickUnixDateTime).toISOString();
    const referrerUserId = data.data_parsed.referrer_user_id;

    mParticle.logEvent('Install_Attributed', mParticle.EventType.Other, {
      platform: 'web',
      linkTitle,
      referringLink,
      referrerUserId,
      clickDateTime,
    });

    if (referrerUserId) {
      localStorageClass.setItem(LocalStorageItems.isUserReferred, true);
      localStorageClass.setItem(LocalStorageItems.referrerUserId, referrerUserId);
      await trackUserAttribute({
        userAttribute: UserAttributeType.UserReferralAttribution,
        value: referrerUserId,
      });
    } else {
      await trackUserAttribute({ userAttribute: UserAttributeType.Attribution, value: linkTitle });
    }
  } catch (error) {
    logError({ error, errorName: 'LogAttributionDataError' });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loginCallback = (response: any) => {
  if (response.httpCode !== 200) {
    logError({
      error: new Error(`loginToAnalytics Error - ${response.body}`),
      errorName: 'LoginCallbackError',
    });
  }
};

export type AttributionData = {
  linkParameters: string;
  $marketing_title: string;
  '~referring_link': string;
  '+click_timestamp': string;
};
