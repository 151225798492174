export enum PATHS {
  LOGIN = '/login',
  EMPLOYEES = '/',
  NEWSFEED = '/newsfeed',

  PRODUCTS_DETAIL = '/products/[slug]',
  PRODUCTS_SEARCH = '/products/search',

  ADMIN = '/admin',
  ADMIN_COMPANIES = '/admin/companies',
  ADMIN_EMPLOYEES = '/admin/employees',
  ADMIN_CLINICIANS = '/admin/clinicians',

  VERIFY = '/email/verify',

  ONBOARDING_WELCOME = '/onboarding/welcome',
  ONBOARDING_INTRO = '/onboarding/intro',
  ONBOARDING_AOS = '/onboarding/area-of-specialization',
  ONBOARDING_PRODUCT_GROUPS = '/onboarding/product-groups',
  ONBOARDING_CLINICAL_AREA_DETAILS = '/onboarding/clinical-area-details',
  ONBOARDING_SUCCESS = '/onboarding/success',
}

export const REST_API_PATHS = {
  newsFeedItemImageUpload: 'newsitemimage/upload',
  companyLogoUpload: 'uploadImage',
};

export const productsDBS3FilePath = 'productsdb.json';

export const ATTRIBUTION_LINKS = {
  productDB: {
    prod: 'https://join.vtail.co/product-db',
    staging: 'https://vtailtelehealth.test-app.link/product-db',
  },
};

export const OUTSIDE_LINKS = {
  productDisclaimer: 'https://www.vtail.co/product-database-disclaimer',
  userAgreement: 'https://www.vtail.co/eula',
  privacyPolicy: 'https://www.vtail.co/privacy-policy',
  supportEmail: 'mailto:technicalsupport@vtail.co',
  appStore: 'https://apps.apple.com/us/app/vtail/id1535534043',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.vtail.app',
};
