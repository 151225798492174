import { DropdownValue } from 'models/global';
import { ItemInput } from 'models/itemInput';
import { GetCompaniesOutput, AreaOfSpecialisation, Employee } from '../../../../types/graphql';

export const formatForDropdown = (arr: ItemInput[] | AreaOfSpecialisation[]): DropdownValue[] => {
  const finalArray: DropdownValue[] = [];
  arr.forEach(item => {
    if (item && item?.id && item?.name?.en) {
      finalArray.push({ value: item.id, label: item.name.en });
    }
  });

  return finalArray.sort((a, b) => (a.label < b.label ? -1 : 1));
};

export const formatCompanies = (arr: GetCompaniesOutput[]): GetCompaniesOutput[] => {
  return arr.sort((a, b) => {
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  });
};

export const formatEmployeesDropdown = (data: Employee[]): DropdownValue[] => {
  const finalArray: DropdownValue[] = [];
  data.forEach(employee => {
    if (employee && employee.id && employee.firstName && employee.lastName) {
      finalArray.push({
        value: employee.id,
        label: `${employee.firstName} ${employee.lastName}`,
      });
    }
  });
  return finalArray;
};

export const getInitialCompanyDropdownValue = (
  data: GetCompaniesOutput[],
  companyId: string,
): DropdownValue => {
  const company = data.filter(item => item.id === companyId);
  return company.map(({ id, name }) => ({ value: id, label: name }))[0];
};

export const formatCompaniesDropdown = (data: GetCompaniesOutput[]): DropdownValue[] => {
  const finalArray: DropdownValue[] = [];

  data.forEach(item => {
    if (item && item.id && item.name) {
      finalArray.push({
        value: item.id,
        label: item.name,
      });
    }
  });

  return finalArray.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
};

export const formatFromDropdown = (data: DropdownValue[]): ItemInput[] => {
  const final: ItemInput[] = [];

  data.forEach((item: DropdownValue) => {
    const drop = { id: item.value, name: { en: item.label } };
    final.push(
      item.areaOfSpecialisationId
        ? { ...drop, areaOfSpecialisationId: item.areaOfSpecialisationId }
        : drop,
    );
  });

  return final;
};
