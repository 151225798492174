/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export class CustomError extends Error {
  constructor(message: string, name: string) {
    super(message);
    this.name = name;
  }
}

const options: Sentry.BrowserOptions = {
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  maxBreadcrumbs: 50,
};

export const init = (): void => {
  Sentry.init(options);
};

type LogErrorInfo = {
  error: unknown;
  errorName: string;
  message?: string;
};

const isError = (e: unknown): e is Error => {
  return e instanceof Error;
};

export const logError = (info: LogErrorInfo): void => {
  const { error, errorName, message } = info;

  if (process.env.ENVIRONMENT === 'dev') {
    console.error(`💣 error: ${error} ${message || ''}`);
  } else {
    if (isError(error)) {
      error.name = errorName;
    }

    Sentry.captureException(error, { extra: { message } });
  }
};

export enum BreadcrumbCategory {
  ARTICLES = 'Articles',
  COMPANY_EMPLOYEES = 'CompanyEmployees',
  CLINICIANS = 'Clinicians',
}

export type Breadcrumb = {
  category: BreadcrumbCategory;
  message: string;
};

export const addBreadcrumb = (breadcrumb: Breadcrumb): void => {
  if (process.env.ENVIRONMENT !== 'dev') {
    Sentry.addBreadcrumb(breadcrumb);
  }
};
